<template>
    <comp-table title="商城自提点库存管理" edit-key="id" :columns="columns" table-api="/gateway/apps/o2o/api/terminal/goods/listByTerminalGoods" :form-component="dataForm"></comp-table>
</template>

<script>
import DataForm from "../inventory/form.vue"
import CompTable from "../../../jointly/components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        return {
            dataForm: DataForm,

            columns: [
                {
                    title: "群组",
                    key: "groupName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "供应商",
                    key: "supplierName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "商品",
                    key: "goodsName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "规格",
                    key: "goodsSpName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "库存",
                    key: "distCount",
                    minWidth: 200,
                    align: "center",
                },
            ],
        }
    },

    methods: {},
}
</script>
<style lang="less"></style>
